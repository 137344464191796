import request from '@/utils/request'

/**
 * <pre>
 *     author    jason
 *     date      2021/2/25
 *     desc
 * </pre>
 */

/**
 * 订单详情
 * @param query
 */
export function getOrderInfo(query) {

    // eslint-disable-next-line no-undef
    return request({
        url: '/order/getOrderInfoByOrderNo',
        method: 'get',
        params: query
    })
}
