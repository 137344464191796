<template>
  <div class="app">
    <p class="order_title">用户信息</p>
    <div>
      <p class="order_subtitle">用户昵称:{{ order.userInfo.nickName }}</p>
      <p class="order_subtitle">用户ID:{{ order.userInfo.userId }}</p>
      <p class="order_subtitle">手机号:{{ order.userInfo.phone }}</p>
      <p class="order_subtitle">客资渠道:{{ order.userInfo.channel }}</p>
    </div>
    <div class="order_back"></div>

    <p class="order_title">
      订单信息
      <span :class="payClass">{{ payTest }}</span>
    </p>
    <div>
      <p class="order_subtitle">订单号:{{ order.orderDetailInfo.orderNo }}</p>
      <p class="order_subtitle">
        下单时间:{{ order.orderDetailInfo.createTimeStr }}
      </p>
      <p class="order_subtitle" v-if="order.orderDetailInfo.payTime">
        支付时间:{{ order.orderDetailInfo.payTime }}
      </p>
      <p class="order_subtitle" v-if="order.orderDetailInfo.state != 1">
        支付方式:{{ order.orderDetailInfo.payWay }}
      </p>

      <div
        v-if="
          order.orderDetailInfo.state == 3 || order.orderDetailInfo.state == 8
        "
      >
        <p class="order_subtitle" v-if="order.orderDetailInfo.payImage">
          支付凭证:
          <van-cell is-link @click="showPopup">
            <van-image
              width="5rem"
              height="5rem"
              fit="contain"
              :src="order.orderDetailInfo.payImage"
            />
          </van-cell>
          <van-popup v-model="show">
            <van-image fit="contain" :src="order.orderDetailInfo.payImage" />
          </van-popup>
        </p>
      </div>
      <div v-if="order.orderDetailInfo.state == 2">
        <div v-if="order.orderDetailInfo.payImage != ''">
          <van-cell is-link @click="showPopup">
            <van-image
              width="5rem"
              height="5rem"
              fit="contain"
              :src="order.orderDetailInfo.payImage"
            />
          </van-cell>
          <van-popup v-model="show">
            <van-image fit="contain" :src="order.orderDetailInfo.payImage" />
          </van-popup>
        </div>
      </div>
      <div v-if="order.orderDetailInfo.state == 8">
        <p class="order_subtitle">
          驳回理由:{{ order.orderDetailInfo.overruleReason }}
        </p>
      </div>
    </div>
    <div class="order_back"></div>

    <p class="order_title" v-if="order.mainService.length > 0">租房服务</p>
    <div
      v-for="item in order.mainService"
      v-bind:key="item.serviceName"
      class="order_subtitle"
    >
      {{ item.serviceName }}
      <!-- 折扣不存在 -->
      <template v-if="item.discountPrice== null">
        <span class="rent_service"
          >{{ item.priceUnit }}&nbsp;&nbsp;{{ item.origPrice }}</span
        >
      </template>
      <template v-else>
        <span v-if="item.origPrice === item.discountPrice" class="rent_service"
          >{{ item.priceUnit }}&nbsp;&nbsp;{{ item.origPrice }}</span
        >
        <!-- 折扣价和原价相同时,只显示原价样式 -->
        <p v-if="item.origPrice !== item.discountPrice" style="float: right">
          <span class="rent_center_top_service"
            >{{ item.priceUnit }}&nbsp;&nbsp;{{ item.origPrice }}</span
          >
          <span>{{ item.priceUnit }}&nbsp;&nbsp;{{ item.discountPrice }}</span>
        </p>
      </template>
    </div>
    <div class="order_back"></div>

    <p class="order_title" v-if="order.valueAddService.length > 0">增值服务</p>
    <div
      v-for="item in order.valueAddService"
      v-bind:key="item.serviceName"
      class="order_subtitle"
    >
      {{ item.serviceName }}
      <!-- 折扣不存在 -->
      <template v-if="item.discountPrice == null">
        <span class="rent_service"
          >{{ item.priceUnit }}&nbsp;&nbsp;{{ item.origPrice }}</span
        >
      </template>
      <template v-else>
        <p v-if="item.discountPrice !== item.origPrice" style="float: right" >
          <span class="rent_center_top_service"
            >{{ item.priceUnit }}&nbsp;&nbsp;{{ item.origPrice }}</span
          >
          <span>{{ item.priceUnit }}&nbsp;&nbsp;{{ item.discountPrice }}</span>
        </p>
        <span v-if="item.discountPrice === item.origPrice" class="rent_service"
          >{{ item.priceUnit }}&nbsp;&nbsp;{{ item.origPrice }}</span
        >
      </template>
    </div>
    <div class="order_back"></div>

    <p class="order_title" v-if="order.coupon != null">
      <van-icon name="coupon" />
      优惠券<span class="rent_service">{{ order.coupon.name }}</span>
    </p>
    <div>
      <span class="coupon-subtitle" v-if="order.coupon != null"
        >服务优惠项:{{ order.coupon.explain }}</span
      >
      <p class="order_subtitle">
        订单金额:CNY {{ order.orderDetailInfo.orderMoney }}
      </p>
      <p class="order_subtitle">优惠金额:CNY {{ discountMoney }}</p>
      <p class="order_subtitle">
        实付金额:CNY {{ order.orderDetailInfo.payMoney }}
      </p>
    </div>
    <div class="order_back"></div>

    <p class="order_title">{{ order.orderDetailInfo.productName }}</p>
    <div>
      <p
        class="order_subtitle"
        v-for="item in order.orderApplyInfo"
        v-bind:key="item.title"
      >
        {{ item.title }}:{{ item.content }}
      </p>
    </div>
    <div class="order_back"></div>

    <div
      class="layui-form-item"
      v-for="item in order.friendList"
      v-bind:key="item.companyName"
    >
      <van-collapse v-model="activeNames">
        <van-collapse-item name="1" title="联系信息" class="friend_title">
          <p class="order_subtitle">姓名:{{ item.friendName }}</p>
          <p class="order_subtitle">
            性别:
            {{
              item.friendSex === 1 ? "男" : item.friendSex === 2 ? "女" : "其他"
            }}
          </p>
          <p class="order_subtitle">电话:{{ item.friendPhone }}</p>
          <p class="order_subtitle">邮箱:{{ item.friendMail }}</p>
          <p class="order_subtitle">微信:{{ item.friendWechat }}</p>
          <p class="order_subtitle">QQ:{{ item.friendQq }}</p>
          <p class="order_subtitle">WhatsApp:{{ item.friendWhatsapp }}</p>
          <p class="order_subtitle">Line:{{ item.friendLine }}</p>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { getOrderInfo } from "@/api/orderDetails";

export default {
  name: "orderPaySuccessDetails",
  data() {
    return {
      show: false,
      activeNames: ["1"],
      order: {
        orderDetailInfo: {},
        mainService: { service: {} },
        userInfo: {},
        valueAddService: {},
        coupon: {},
        orderApplyInfo: {},
        friendList: {},
        friendSex: "",
      },
      discountMoney: "",
      payImage: "",
      //已付款
      payed: 2,
      //待审核
      toBeReviewPay: 3,
      //待支付
      toBePay: 1,
      //审核驳回
      orderReject: 8,
    };
  },
  computed: {
    payClass() {
      let payState = this.order.orderDetailInfo.state;
      let resultClass = "";
      if (payState == this.payed) {
        resultClass = "payed";
      } else if (payState == this.toBeReviewPay) {
        resultClass = "toBeReviewPay";
      } else if (payState == this.toBePay) {
        resultClass = "toBePay";
      } else if (payState == this.orderReject) {
        resultClass = "orderReject";
      }
      return resultClass;
    },
    payTest() {
      let payState = this.order.orderDetailInfo.state;
      let payName = "";
      if (payState == this.payed) {
        payName = "已付款";
      } else if (payState == this.toBeReviewPay) {
        payName = "待审核";
      } else if (payState == this.toBePay) {
        payName = "待支付";
      } else if (payState == this.orderReject) {
        payName = "审核驳回";
      }
      return payName;
    },
  },
  created() {
    let orderNo = this.$route.query.orderNo;
    if (!orderNo) {
      this.$failToast("参数错误");
      return;
    }
    getOrderInfo({
      orderNo: orderNo,
    }).then((res) => {
      console.log("订单详情结果===>", res);
      this.order = res.data;
      this.discountMoney =
        this.order.orderDetailInfo.orderMoney -
        this.order.orderDetailInfo.payMoney;
      this.payImage = this.order.orderDetailInfo.payImage;
      this.loading = false;
    });
  },
  methods: {
    showPopup() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 800px) {
  .app {
    box-sizing: border-box;
    padding: 50px;
    width: 100% !important;
    background-color: #f9f9f9 !important;
    margin-top: 500px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) scale(1.5);
    max-width: 1200px;
  }
}
.order_back {
  background-color: #dddddd;
  height: 2px;
  line-height: 20px;
}

.order_title {
  padding-top: 10px;
  padding-left: 15px;
  font-size: 20px;
  font-weight: bold;
}

.order_subtitle {
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  line-height: 40px;
}

p {
  line-height: 40px;
}

.payed {
  color: #34ce57;
  padding-left: 200px;
}

.toBeReviewPay {
  color: #c69500;
  padding-left: 200px;
}

.toBePay {
  color: #1da1f2;
  padding-left: 200px;
}

.orderReject {
  color: red;
  padding-left: 180px;
}

.coupon-subtitle {
  font-size: 14px;
  font-weight: bold;
  padding-left: 15px;
  line-height: 5px;
}

.rent_center_service {
  padding-left: 35px;
  text-decoration: line-through;
  color: #9b9b9b;
}

.rent_center_top_service {
  padding-right: 25px;
  text-decoration: line-through;
  color: #9b9b9b;
}

.add_service {
  padding-left: 45px;
  text-decoration: line-through;
  color: #9b9b9b;
}

.rent_service {
  padding-left: 120px;
}

.friend_info {
  float: right;
}

.friend_title {
  font-size: 50px;
  font-weight: bold;
}
</style>
